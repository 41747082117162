import "./App.css";
import useTimeUntil, { pad } from "./hooks/useTimeUntil";

function App() {
  const { due, totalHours, minutes, seconds } = useTimeUntil(
    new Date(2024, 2, 23, 19)
  );
  const timer = `${pad(totalHours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  return (
    <>
      <video id="bgVideo" controls={false} preload="true" autoPlay loop muted>
        <source src="logoloop.mp4" type="video/mp4" />
      </video>
      <div
        className="main"
        style={{ backgroundColor: due ? "transparent" : undefined }}
      >
        {due ? null : <div className="centered">{timer}</div>}
      </div>
    </>
  );
}

export default App;
